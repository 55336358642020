<template>
    <div class="nevs-content">
        <div class="nevs-content-top-buttons">
            <NevsButton class="success help-save" @click="saveClick()"><i class="fa-solid fa-floppy-disk"></i>
                {{ $LANG.Get('buttons.addPayments') }}
            </NevsButton>&nbsp;
            <NevsButton class="error help-back" @click="backClick"><i class="fa-solid fa-arrow-left"></i>
                {{ $LANG.Get('buttons.back') }}
            </NevsButton>
        </div>
        <NevsCard class="form">
            <table>
                <tr class="help-multiple-payments-details">
                    <th style="width: 50px">&nbsp;</th>
                    <th style="width: 150px;">{{ $LANG.Get('labels.date') }}</th>
                    <th style="width: 200px;">{{ $LANG.Get('labels.invoice') }}</th>
                    <th style="width: 150px;">{{ $LANG.Get('labels.amount') }}</th>
                    <th></th>
                </tr>
                <tr v-for="(payment, key) in payments" :key="key">
                    <td style="text-align: center; line-height: 50px;">{{ key + 1 }}</td>
                    <td>
                        <NevsDateField v-model="payment.date"></NevsDateField>
                    </td>
                    <td>
                        <NevsSelect :nullable="true" v-model="payment.invoice_id"
                                    :ajax="'select/invoices'"></NevsSelect>
                    </td>
                    <td>
                        <NevsNumberField v-model="payment.amount"></NevsNumberField>
                    </td>
                    <td style="padding: 10px;">
                        <div v-html="notes[key]"></div>
                        <div class="warning" v-html="warnings[key]"></div>
                    </td>
                </tr>
            </table>
        </NevsCard>
    </div>
</template>

<script>
import NevsCard from "@/components/nevs/NevsCard";
import NevsButton from "@/components/nevs/NevsButton.vue";
import NevsSelect from "@/components/nevs/NevsSelect.vue";
import NevsDateField from "@/components/nevs/NevsDateField.vue";
import NevsNumberField from "@/components/nevs/NevsNumberField.vue";
import moment from "moment/moment";

export default {
    name: "ModuleDailyReport",
    components: {NevsNumberField, NevsDateField, NevsSelect, NevsButton, NevsCard},
    data() {
        return {
            payments: [
                {
                    invoice_id: null,
                    date: null,
                    amount: 0
                }
            ],
            paymentsCache: [
                {
                    invoice_id: null,
                    date: null,
                    amount: 0
                }
            ],
            notes: [''],
            warnings: [this.$LANG.Get('labels.invoiceNotSelected') + '<br />' + this.$LANG.Get('labels.fatalPayment')],
            initialized: [false],
            fatal: [true]
        }
    },
    watch: {
        payments: {
            handler() {
                this.payments.forEach((newPayment, index) => {
                    const oldPayment = this.paymentsCache[index];
                    if (JSON.stringify(newPayment) !== JSON.stringify(oldPayment)) {
                        this.paymentChanged(index);
                        this.paymentsCache[index] = JSON.parse(JSON.stringify(this.payments[index]));
                    }
                });
                let lastPayment = this.payments[this.payments.length - 1];
                if (lastPayment.invoice_id !== null) {
                    this.notes.push('');
                    this.warnings.push(this.$LANG.Get('labels.invoiceNotSelected') + '<br />' + this.$LANG.Get('labels.fatalPayment'));
                    this.initialized.push(false);
                    this.fatal.push(true);
                    let newPayment = {
                        invoice_id: null,
                        date: lastPayment.date,
                        amount: 0
                    };
                    this.paymentsCache.push(JSON.parse(JSON.stringify(newPayment)));
                    this.$nextTick(() => {
                        this.payments.push(JSON.parse(JSON.stringify(newPayment)));
                    });
                }
            },
            deep: true
        }
    },
    methods: {
        saveClick() {
            let fatals = [];
            for (let paymentIdx = 0; paymentIdx < this.payments.length; paymentIdx++) {
                if (this.fatal[paymentIdx]) fatals.push(paymentIdx + 1);
            }
            let fatalsString = fatals.join(', ');
            let alert = this.$LANG.Get('alerts.multiplePaymentsConfirmation');
            alert = alert.split('{fatals}').join(fatalsString);
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {type: 'confirm', text: alert, callback: (response) => {
                if (response) {
                    this.$API.APICall('post', 'invoices/multiple-payments', {payments: this.payments}, (data, success) => {
                        if (!success) {
                            vm.$LOCAL_BUS.TriggerEvent('popup', {
                                text: vm.$LANG.Get('alerts.serverError'),
                                type: 'alert'
                            });
                        }
                        vm.$router.back();
                    }, false);
                }
            }});
        },
        backClick() {
            let vm = this;
            this.$LOCAL_BUS.TriggerEvent('popup', {type: 'confirm', text: this.$LANG.Get('alerts.leaveSaveQuestion'), callback: (response) => {
                    if (response) {
                        vm.$router.back();
                    }
                }});
        },
        paymentChanged(paymentIdx) {
            let vm = this;
            let today = moment().format('YYYY-MM-DD');
            let payment = this.payments[paymentIdx];
            if (payment.invoice_id !== null) {
                this.$API.APICall('get', 'invoices/' + payment.invoice_id, {}, (data, success) => {
                    if (success) {
                        let fatal = false;
                        let note = '';
                        note += vm.$LANG.Get('fields.customer') + ': ' + data.invoice.customer_data.name + '<br />';
                        note += vm.$LANG.Get('fields.dateOfIssue') + ': ' + data.invoice.date_of_issue_display + '<br />';
                        note += vm.$LANG.Get('fields.amount') + ': ' + data.invoice.amount_display + '<br />';
                        note += vm.$LANG.Get('labels.open') + ': ' + data.invoice.financial_status_display + '<br />';
                        note += vm.$LANG.Get('fields.currency') + ': ' + data.invoice.currency + '<br />';
                        vm.notes[paymentIdx] = note;
                        let warning = '';
                        if (payment.date == null) {
                            warning += vm.$LANG.Get('labels.dateNotSelected') + '<br />'
                            fatal = true;
                        } else if (payment.date > today) {
                            warning += vm.$LANG.Get('labels.dateInTheFuture') + '<br />'
                            fatal = true;
                        }
                        if (payment.amount === data.invoice.financial_status) {
                            vm.initialized[paymentIdx] = true;
                        }
                        if (!vm.initialized[paymentIdx]) {
                            vm.payments[paymentIdx].amount = data.invoice.financial_status;
                        }
                        if (payment.amount <= 0) {
                            warning += vm.$LANG.Get('labels.amountNotGreaterThanZero') + '<br />'
                            fatal = true;
                        } else if (payment.amount !== data.invoice.financial_status) {
                            warning += vm.$LANG.Get('labels.amountDifferentThanFinancialStatus') + '<br />'
                        }
                        vm.fatal[paymentIdx] = fatal;
                        if (vm.fatal[paymentIdx]) {
                            warning += vm.$LANG.Get('labels.fatalPayment') + '<br />';
                        }
                        vm.warnings[paymentIdx] = warning;
                    }
                }, false);
            } else {
                this.fatal[paymentIdx] = true;
                this.warnings[paymentIdx] = this.$LANG.Get('labels.invoiceNotSelected') + '<br />' + this.$LANG.Get('labels.fatalPayment');
            }
        }
    },
    mounted() {
        this.$store.commit('selectMenu', 'invoices');
        this.$store.commit('selectSubMenu', null);
        this.$store.commit('setBreadcrumbs', [
            {
                label: this.$LANG.Get('modules.invoices'),
                link: '/invoices'
            },
            {
                label: this.$LANG.Get('modules.multiplePayments'),
                link: null
            }
        ]);
        this.$store.commit('setHelpOverlay', [
            {
                class: 'help-multiple-payments-details',
                html: this.$LANG.Get('help.multiplePaymentsDetails'),
                left: 0,
                top: 0,
                width: 300
            }
        ]);
    }
}
</script>

<style scoped>
.form {
    width: 100%;
    max-width: 1000px;
    margin-bottom: 10px;
}

.form table {
    width: 100%;
    border-collapse: collapse;
}

.form table tr {
    background-color: #dfeaef;
}

.form table tr:hover {
    background-color: #d2e1e8;
}

.form table tr:first-child {
    background: #b8cfdb;
}

.form table th {
    border-bottom: none;
    height: 40px;
}

.form table td {
    border-bottom: 1px solid #c5d8e2;
    vertical-align: top;
    padding-top: 10px;
    padding-bottom: 10px;
}

>>> .warning {
    color: red;
}

</style>